<template>
	<div class="box">

		<el-container class="fullHeight">
			<!-- 顶部 -->
			<el-header class="header">
				<div class="logo">
					<img src="../../../src/assets/logo.png" alt="" />
				</div>
				<div class="title">
					<h4>职业院校德育管理系统</h4>
					<div class="user">
						<el-dropdown @command="handleCommand">
							<el-avatar size="large" fit="fill">
								<img :src="imgBaseUrl + userPhoto" />
							</el-avatar>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item icon="el-icon-s-tools" command="edit">修改密码</el-dropdown-item>
								<el-dropdown-item icon="el-icon-s-promotion" command="exit">退出</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-dropdown @command="handleCommand">
							<el-button type="text" style="color:white">
								<b>{{ userName }}</b> / {{ userRose }}
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item icon="el-icon-s-tools" command="edit">修改密码</el-dropdown-item>
								<el-dropdown-item icon="el-icon-s-promotion" command="exit">退出</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</el-header>

			<!-- 左侧菜单 -->
			<el-container class="container_box">
				<el-aside width="200px">
					<el-menu class="el-menu-vertical-demo" background-color="#314158" text-color="#fff" active-text-color="#54a2e3" :default-active="$route.path" router>
						<!-- 二级菜单 -->
						<el-submenu v-for="item in menuList" :key="item.id" :index="item.name">
							<template slot="title">
								<i :class="item.icon"></i>
								<span class="fs17">{{ item.name }}</span>
							</template>
							<el-menu-item-group>
								<el-menu-item v-for="childItem in item.children" :key="childItem.id" :index="childItem.permission" class="pl10 pr10">
									<span v-if="childItem.permission.length > 0">{{ childItem.name }}</span>
									<a v-if="childItem.permission.length <= 0" :href='childItem.url' target='_blank' style='color:white;text-decoration:none'>{{ childItem.name }}</a>
									
									<!-- <router-link v-if="childItem.permission.length <= 0" tag="a" target="_blank" :to="{path: '/bigScreen'}" ><span style='color:white;text-decoration:none'>{{ childItem.name }}</span></router-link> -->
									
								</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
					</el-menu>
				</el-aside>
				<el-main>
					<router-view></router-view>
				</el-main>
			</el-container>

			<!-- 底部 -->
			<el-footer class="footer">{{siteRecordNum}}</el-footer>
		</el-container>

		<!-- 修改密码 弹窗 -->
		<el-dialog title="修改密码" :visible="dialogVisible" width="30%" :before-close="handleClose" center>
			<el-form :model="pwdChangeForm" status-icon :rules="rules" ref="pwdChangeForm" label-width="120px"
				class="demo-pwdChangeForm" size="small">
				<el-form-item label="旧密码" prop="oldPwd">
					<el-input type="password" v-model="pwdChangeForm.oldPwd"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPwd">
					<el-input type="password" v-model="pwdChangeForm.newPwd"></el-input>
				</el-form-item>
				<el-form-item label="再次输入新密码" prop="checkPwd">
					<el-input type="password" v-model="pwdChangeForm.checkPwd"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitPwdChangeForm('pwdChangeForm')">保 存</el-button>
					<el-button @click="resetForm('pwdChangeForm')">清 空</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
	import {
		getToken
	} from "@/utils/token.js";
	import * as api from "@/api/users";
	import {
		getHttpUrl,
		getSiteRecordNum
	} from "@/utils/request";
	import * as login from "@/api/login";
	export default {
		data() {
			var validateOldPwd = (rule, value, callback) => {
				if (value == "") {
					callback(new Error("请输入旧密码"));
				} else {
					// 有错误
					// if (this.pwdChangeForm.oldPwd != "") {
					//   this.$refs.pwdChangeForm.validateField("oldPwd");
					// }
					callback();
				}
			};
			var validateNewPwd = (rule, value, callback) => {
				if (value == "") {
					callback(new Error("请输入新密码"));
				} else {
					// 有错误
					// if (this.pwdChangeForm.newPwd != "") {
					//   this.$refs.pwdChangeForm.validateField("newPwd");
					// }
					callback();
				}
			};
			var validateCheckPwd = (rule, value, callback) => {
				if (value == "") {
					callback(new Error("请再次输入新密码"));
				} else if (value != this.pwdChangeForm.checkPwd) {
					callback(new Error("两次输入新密码必须相同!"));
				} else {
					callback();
				}
			};
			return {
				siteRecordNum: getSiteRecordNum(), // 备案号
				token: '', // token登录
				id: '', // 登录id
				type: "",
				userName: "", // 登录名
				userRose: "", // 登录人角色
				userPhoto: "", // 登录人头像
				item: {
					urlteac: "/userArgument",
					urlt: "/",
				},
				allMenuList: [], // 全部菜单定义
				roleMenuList: [], // 所有拥护的权限
				menuList: [], // 最终的二级菜单
				imgBaseUrl: getHttpUrl(),
				dialogVisible: false, //修改密码
				pwdChangeForm: {
					oldPwd: "",
					newPwd: "",
					checkPwd: "",
				},
				rules: {
					oldPwd: [{
						validator: validateOldPwd,
						trigger: "blur"
					}],
					newPwd: [{
						validator: validateNewPwd,
						trigger: "blur"
					}],
					checkPwd: [{
						validator: validateCheckPwd,
						trigger: "blur"
					}],
				},
			};
		},
		methods: {
			// 确认修改密码
			submitPwdChangeForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (this.pwdChangeForm.oldPwd == null || this.pwdChangeForm.oldPwd == "") {
						this.$message.error("请输入旧密码");
						return false;
					}
					if (this.pwdChangeForm.newPwd == null || this.pwdChangeForm.newPwd == "") {
						this.$message.error("请输入新密码");
						return false;
					}
					if (this.pwdChangeForm.newPwd != this.pwdChangeForm.checkPwd) {
						this.$message.error("两次输入新密码必须相同");
						return false;
					}
					if (valid) {
						api.editPassword({
							oldPwd: this.pwdChangeForm.oldPwd,
							newPwd: this.pwdChangeForm.newPwd,
							uid: this.id,
							Authorization: this.token
						}).then(res => {
							if (res.status == 1) {
								this.$message({
									message: res.data,
									type: 'success'
								});
								// 关不窗口
								this.dialogVisible = false;
								this.exit();
							} else {
								this.$message({
									message: res.msg,
									type: 'error'
								});
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 点击菜单(修改密码、退出)
			handleCommand(menu) {
				menu === "edit" ? this.changePass() : this.exit();
			},
			// 显示修改密码框
			changePass() {
				this.dialogVisible = true;
			},
			// 关闭修改密码框
			handleClose() {
				this.dialogVisible = false;
			},
			// 退出
			exit() {
				this.token = getToken();
				login.logOut(this.token).then((res) => {
					if (res.status === 3) {
						this.$message({
							message: res.msg,
							type: "success",
						});
					}
				});
				this.$router.push({
					path: "/login",
				});
			},
			// 路由列表
			getRouterList() {
				let roleid = JSON.parse(sessionStorage.getItem("userinfo")).roleid;
				let thatTemp = this;
				// Step 1 读取所有的菜单
				api.getAllMenuPageList().then(res1 => {
					let allData = res1.data;
					if (allData != null) {
						thatTemp.allMenuList = allData;
						sessionStorage.setItem('allMenuList', JSON.stringify(thatTemp.allMenuList));

						// Step 2 读取 当前 RoleID 具有的 菜单列表
						let params = {
							id: roleid,
						};
						api.getRoleList(params).then(res2 => {
							let roleData = res2.data;
							thatTemp.roleMenuList = roleData.records[0].resources;
							let menuListNew = [];
							let roleMenuListNew = [];
							let allMenuList = thatTemp.allMenuList;
							let roleMenuList = thatTemp.roleMenuList;
							// alert(thatTemp.allMenuList);  // 所有菜单 页面
							// alert(thatTemp.roleMenuList); // 当前角色 的 菜单页面
							if (allMenuList != null) {
								// 拼接出 最终的 菜单。
								for (let i = 0; i < allMenuList.length; i++) {
									let temp1 = allMenuList[i];
									// alert(temp1.name);  // 所有菜单 页面
									if (temp1 != null && temp1.children != null && temp1.children.length >
										0) {
										let childMenuList = [];
										for (let j = 0; j < temp1.children.length; j++) {
											for (let k = 0; k < roleMenuList.length; k++) {
												if (temp1.children[j].id == roleMenuList[k].id) {
													childMenuList.push(roleMenuList[k]);
													roleMenuListNew.push(roleMenuList[k]);
													roleMenuList.splice(k, 1); // 移除1个元素
													break;
												}
											}
										}
										// alert(childMenuList);
										// 换成清理后的 菜单
										if (childMenuList.length > 0) {
											temp1.children = childMenuList;
											menuListNew.push(temp1);
										}
									}
								}
								thatTemp.menuList = menuListNew;
								thatTemp.roleMenuList = roleMenuListNew;
								sessionStorage.setItem('menuList', JSON.stringify(menuListNew));
								sessionStorage.setItem('roleMenuList', JSON.stringify(roleMenuListNew));
							}
						});
					}
				});
			},
		},
		mounted() {
			this.token = getToken();
			api.getCurrUserItem(this.token).then((res) => {
				sessionStorage.setItem("userinfo", JSON.stringify(res.data));
				this.userName = res.data.name;
				this.userRose = res.data.rname;
				this.userPhoto = res.data.photo;
				this.id = res.data.id;
				this.getRouterList();
			});
		},
	};
</script>

<style lang='scss' scoped>
	body {
		background-color: #314158;
	}

	.container_box {
		overflow: hidden;
	}

	.box {
		height: 100%;

		.header {
			height: 100px !important;
			background: #314158;

			.logo {
				float: left;
				width: 200px;
				text-align: center;

				img {
					width: 80%;
					margin-top: 26px;
				}
			}

			.title {
				margin-left: 250px;
				margin-right: 50px;
				text-align: left;
				display: flex;
				justify-content: space-between;
				color: #ffffff;

				.user {
					height: 40px;
					margin: 30px 0;

					.el-dropdown {
						float: left;
						line-height: 40px;
					}

					.text {
						float: left;
						line-height: 40px;
						font-size: 12px;
						color: #d1d1d1;

						b {
							font-size: 14px;
							color: #fff;
						}
					}

					.el-avatar {
						vertical-align: middle;
						margin-right: 8px;
						cursor: pointer;
					}
				}
			}
		}

		.footer {
			height: 30px !important;
			background: #314158;
			font-size: 14px;
			line-height: 30px;
			text-align: center;
			color: #cccccc;
		}
	}

	.fullHeight {
		min-height: 100vh;
	}

	// 左侧菜单
	.el-aside {
		width: 200px;
		min-height: 100%;
		overflow: hidden;
		background-color: #314158;
	}

	.el-menu {
		width: 210px;
		/* 多出20像素是滚动条的位置，会被父容器盖住就看不到了 */
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.el-main {
		padding: 0;
	}

	.el-header {
		background-color: #b3c0d1;
		color: #333;
		text-align: center;
		line-height: 100px;
		font-size: 30px;
		padding: 0;
	}

	.el-aside {
		color: #333;
		width: 240px;
	}

	.el-submenu {
		background-color: #072e60 !important;
	}

	.el-menu-item-group {
		top: -14px;
		margin-top: -14px;
	}

	.el-menu-item-group__title {
		height: 0;
		overflow: hidden;
		padding: 0 !important;
	}

	.el-menu {
		padding: 0;
		height: 100%;
		border-right: 0;

		.el-menu-item-group__title {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			display: none !important;
			height: 0;
			overflow: hidden;
			padding: 0 !important;
		}

		.el-menu-item {
			font-size: 16px;
			font-weight: 600;
			background-color: #1f2d3d !important;
			width: 200px;
			color: red;
			min-width: none;
			padding-left: 10px !important;
			text-align: center;
		}

		.is-active {
			background-color: #1a242f !important;
		}
	}

	.el-avatar>img {
		width: 100%;
		height: auto !important;
	}
</style>
